import React, { useEffect, useState, useRef, useCallback, useContext } from 'react'
import { base_url } from '../../utils/helper'
import { useLocation } from 'react-router-dom'
import { HistoryIcon, HeartIcon, WatchListIcon } from '../Icons'
import { CircularProgress, CardContent, Typography, Grid, Skeleton, Link } from '@mui/material'
import { StyledRowCardActions, StyledRowCard, StyledRowCardMedia, StyledRowCardContentContainerDiv, StyledRowCardContentIconsDiv, StyledRowTypography, StyledCircularProgressDiv } from '../../utils/styled'
import styled from 'styled-components'
import { setHref } from './setHref'
import { MovieceptionContext } from '../../context/MovieceptionContext'

const StyledTypography = styled(Typography)`
  text-align: center;
  max-width: 270px;
  text-wrap: wrap;
`

function Row({ fetchUrl, title, currentPage, setCurrentPage, marvel }) {
    const [movies, setMovies] = useState([])
    const [loading, setLoading] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)
    const [opacity, setOpacity] = useState(0)

    const { data: user } = useContext(MovieceptionContext)

    useEffect(() => {
        setTimeout(() => {
            setOpacity('1')
        }, 800)
    }, [])

    // complicated way of getting idx lol, currentPage times 19;
    const cPxNtn = currentPage *= 19

    const isMovie = window.location.href.includes('movies')
    const isShow = window.location.href.includes('shows')
    const isNetflix = window.location.href.includes('netflix')

    const search = useLocation().search
    const input = new URLSearchParams(search).get('input')

    const observer = useRef()
    const lastMovieRow = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setCurrentPage(prevPage => prevPage + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, setCurrentPage])

    useEffect(() => {
        const fetchData = async () => {
            if (currentPage <= 1) {
                setLoading(true)
            } else {
                setMoreLoading(true)
            }
            fetchUrl
                .then(res => {
                    if (!res) return
                    const hrefDRes = setHref(res, isNetflix, isMovie, isShow, marvel)
                    if (currentPage <= 1) {
                        setMovies(hrefDRes)
                    } else {
                        setMovies(previousMovies => {
                            return previousMovies.concat(hrefDRes)
                        })
                    }
                })
                .finally(() => {
                    setLoading(false)
                    setMoreLoading(false)
                })
        }
        if (!fetchUrl) return
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUrl, currentPage])

    return (
        <>
            {loading ? (
                <StyledCircularProgressDiv>
                    <CircularProgress size={70} />
                </StyledCircularProgressDiv>
            ) : (
                <Grid container>
                    <Grid item xs={12} style={{ transition: 'opacity ease-in-out 1s' }} sx={{ opacity: opacity }}>
                        <StyledRowTypography variant="h4">{title}</StyledRowTypography>
                        <Grid container justifyContent="center" spacing={5}>
                            {movies?.map((movie, idx) => {
                                return (movie?.poster_path || movie?.backdrop_path || movie?.profile_path) && (
                                    <Grid item key={idx} ref={idx === cPxNtn ? lastMovieRow : null}>
                                        <StyledRowCard
                                            sx={{
                                                maxWidth: 312,
                                                minHeight: 550,
                                                transition: 'box-shadow .7s ease-in-out',
                                                '&: hover': {
                                                    boxShadow: '0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9)'
                                                }
                                            }}
                                        >
                                            {!(movie?.poster_path || movie?.backdrop_path || movie?.profile_path) ? (
                                                <Skeleton variant="rectangular" width={312} height={468} />
                                            ) : (
                                                <Link href={movie.href}>
                                                    <StyledRowCardMedia
                                                        style={{ opacity: opacity }}
                                                        sx={[{ height: 468, width: 312, backgroundSize: 'contain' }]}
                                                        image={
                                                            (movie?.poster_path || movie?.backdrop_path || movie?.profile_path) &&
                                                            `${base_url}${movie?.poster_path || movie?.backdrop_path || movie?.profile_path}`
                                                        }
                                                    />
                                                </Link>
                                            )}
                                            <StyledRowCardActions>
                                                <StyledTypography
                                                    component={'span'}
                                                    variant='h6'
                                                >
                                                    {movie?.title || movie?.original_title || movie?.name || movie?.original_name}
                                                </StyledTypography>
                                            </StyledRowCardActions>
                                            <CardContent>
                                                {!(movie?.media_type === 'person' || input === 'actor') && (
                                                    user?.username &&
                                                    <StyledRowCardContentContainerDiv>
                                                        <StyledRowCardContentIconsDiv>
                                                            <HistoryIcon user={user} movie={movie} />
                                                            <HeartIcon user={user} movie={movie} />
                                                            <WatchListIcon user={user} movie={movie} />
                                                        </StyledRowCardContentIconsDiv>
                                                    </StyledRowCardContentContainerDiv>
                                                )}
                                            </CardContent>

                                        </StyledRowCard>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        {moreLoading && (
                            <StyledCircularProgressDiv>
                                <CircularProgress size={70} />
                            </StyledCircularProgressDiv>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default Row
